.slider {
  position: relative;
  z-index: 1;
  min-height: 400px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 18px;
  margin-top: 20px;

  .stage {
    min-height: auto;
    max-height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .blackshadow {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 212px;
        height: 212px;
        position: absolute;

        &.topleft {
          left: 0px;
          top: 0px;
        }
        &.topright {
          right: 0px;
          top: 0px;
          transform: rotate(90deg);
        }
        &.bottomleft {
          left: 0px;
          bottom: 0px;
          transform: rotate(-90deg);
        }
        &.bottomright {
          bottom: 0px;
          right: 0px;
          transform: rotate(-180deg);
        }
      }
    }
    img,
    video {
      object-fit: contain !important;
      width: 100%;
      max-height: 80vh; //change
      height: auto;
    }
    video {
      width: 100%;
      height: 60vh;
      background: black;
      object-fit: fill !important; //changed
    }
  }

  button.prev,
  button.next,
  button.closebtn,
  button.enlargeBtn {
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;
    outline: none;
  }
  button.closebtn {
    position: absolute;
    left: 18px;
    top: 0px;
  }
  button.enlargeBtn {
    position: absolute;
    right: 18px;
    top: 0px;
    z-index: 9;
  }
  button.prev {
    position: absolute;
    left: 18px;
    bottom: 50%;
    transform: translateY(15px);
    z-index: 99;
  }

  .next {
    position: absolute;
    right: 18px;
    bottom: 50%;
    transform: translateY(15px);
    z-index: 99;
  }
}

@media screen and (max-width: 1024px) {
  .slider {
    min-height: 0;
    .stage {
      min-height: 0;
      height: auto;
      img,
      video {
        max-height: 55vh !important;
        height: auto !important;
        object-fit: contain !important;
      }
      video {
        width: 100%;
        height: 400px !important;
        object-fit: fill !important;
      }
    }
  }
}

@media screen and (max-width: 590px) {
  .slider {
    min-height: 0;
    .stage {
      min-height: 0;
      height: auto;
      img,
      video {
        max-height: 50vh !important;
        height: auto !important;
        object-fit: contain !important;
      }
      video {
        width: 100%;
        height: 360px !important;
        object-fit: fill !important;
      }
    }
  }
}
