/*
==================================================================
                        Comments 
==================================================================
*/
#newsfeed {
  .commentBox {
    background: var(--light-gray-color);
    display: flex;
    flex-direction: column;
    padding: 18px 18px 18px 18px;
    .all {
      color: var(--gray-color);
      text-align: center;
      font-size: 12px;
      padding: 0px 0 10px 0;
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        display: block;
        border-bottom: solid 1px var(--border-color);
        position: absolute;
        top: 8px;
        left: 0px;
        width: 100%;
      }
      span {
        background: var(--light-gray-color);
        padding: 0 25px;
        position: relative;
        z-index: 5;
      }
    }
    .commentsMsg {
      background: white;
      padding: 15px 15px 5px 0px;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .userComment {
      display: flex;
      flex-direction: row;
      padding: 0 0 0 10px;
    }
    .rightSide {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .commentForm {
        background: rgba(245, 245, 245, 0.5);
        min-height: 40px;
        margin-bottom: 10px;
        .leftSide {
          padding-left: 0px;
          .comment {
            padding: 10px 10px 0 10px;
            min-height: 40px;
            word-break: break-all;
            color:#333;
          }
        }
        .rightSide {
          padding-top: 5px;
          button {
            margin-right: 5px;
          }
        }
      }
    }
    .rightTop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .actionBtns {
        position: relative;
        display: flex;
        button {
          margin-left: 5px;
        }
      }
    }
    .userInfo {
      display: flex;
      height: 25px;
      padding-left: 10px;
      flex-direction: row;
      align-items: center;

      h4 {
        font-size: 12px;
        margin: 0px;
        padding: 0px;
      }
      .timeago {
        color: var(--gray-color);
        padding-left: 8px;
        font-size: 11px;
      }
    }
    .actionBtns {
      button {
        margin: 0px;
        padding: 0px;
        background: none;
        border: none;
      }
      span {
        font-size: 12px;
        padding: 0 3px 0 3px;
        color: var(--gray-color);
      }
    }
    .userCont {
      font-size: 12px;
      padding: 0px 20px 10px 10px;
      word-break: break-word;
      .commentTxt[contenteditable='true'] {
        background: gray;
      }
    }
    .commentForm {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 50px;
      align-items: flex-start;
      background: white;

      .leftSide {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        width: 100%;
        padding-left: 10px;
        div.img {
          padding-top: 10px;
          img {
            width: 30px;
            height: 30px;
            display: flex;
            flex: 1 1 auto;
          }
        }

        .comment {
          color: #333333;
          font-size: 12px;
          min-height: 40px;
          padding: 15px 10px 0 10px;
          border: none;
          background: none;
          width: 100%;
          flex: 1 1 auto;
          max-width: calc(100vw - 203px);
          &:focus {
            outline: none;
          }
        }
      }
      .rightSide {
        padding-top: 10px;
        display: flex;
        flex: 1 1 auto;
      }
      button.sendComment {
        font-weight: normal;
        color: var(--black-color);
        font-size: 12px;
        color: white;
        &:disabled {
          opacity: 0.3;
          color: var(--black-color);
        }
      }
      &.replyForm {
        .leftSide {
          div.img {
            padding-top: 5px;
            padding-left: 5px;
          }
        }
      }
    }
    & > .commentBox {
      padding: 0px 0 9px 0;
    }
  }
}

@media only screen and (min-width: 220px) and (max-width: 480px) {
  #newsfeed .commentBox {
    padding: 10px;
    .userCont{
      padding-left:10px;
    }
    .userComment{
      .userComment{
        padding-left:0px;
      }
    }
    .rightSide{
      .userInfo{
        flex-direction: column;
        height:auto;
        align-items: flex-start;
        span{
          padding-left:0px;
        }
      }
      .rightSide{
        &>div{
         // margin-left:-25px
        }
      }
     
    }
  }
  #newsfeed .commentBox .commentForm {
    .rightSide {
      flex: 1 1 35px;
    }
    .leftSide{
      .comment{
        max-width: calc(100vw - 114px);
      }
    }
  }
  #newsfeed .commentBox .commentForm button.sendComment {
    padding: 0px;
    background: transparent;
    width: 28px;
    height: 28px;
    span {
      display: none;
    }
    img {
      padding: 3px;
    }
  }
  #newsfeed .commentBox .commentForm button.sendComment:disabled {
    opacity: 1;
    img{
      width:30px;
    }
  }
}
